<template>
	<div class="">
		<el-dialog class=""  :visible.sync="dialogVisible" width="800px" center :show-close="false" :modal-append-to-body="false">
			<div class="registersuccess">
				<span class="closeicon" @click="closebox()">
					<i class="el-icon-error"></i>
				</span>
				<div class="gou_img">
					<img src="../../assets/images/common/position-nodata.png" >
				</div>
				<div class="congratulations font18">
					您的简历未完善或视频简历正在审核中
				</div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
import Bus from "../../common/bus.js"
export default {
	data() {
		return {
			dialogVisible: false,
			jubaoForm: {
				type: '',
				content:'',
				imgurl:'',

			},
			gzoptions: [
				{
					value: '09:00',
					label: '09:00'
				},
				{
					value: '10:00',
					label: '10:00'
				},
			],
			updialogVisible: false




		};
	},
	mounted() {
		Bus.$on('wanshandstuts', (data) => {
			console.log('举报接受到',data)
			this.dialogVisible=data;
		})
	},
	methods: {
		closebox(){
			this.dialogVisible=false;
			Bus.$emit('wanshandstuts', false)
		},
		handleRemove(file, fileList) {
			// console.log(file, fileList);
		},
		handlePictureCardPreview(file) {
			this.jubaoForm.imgurl = file.url;
			this.updialogVisible = true;
		},







	}
};
</script>
<style type="text/css">
.el-dialog__header,
.el-dialog__body {
	padding: 0 !important;
}
</style>
<style lang="less" scoped>
// .closeicon{
// 		position: absolute;
// 		top:-40px;
// 		right: 0;
// 		font-size:26px;
// 		color: #F3F3F3;
// 	}
	.registersuccess{
		padding: 84px 0;
		position: relative;
		.gou_img{
			text-align: center;
			img{
				width: 257px;
				height: 216px;
			}
		}
		.congratulations{
			text-align: center;
			color: #111111;
		}
		.closeicon{
			position: absolute;
			top:-40px;
			right: 0;
			font-size:26px;
			color: #F3F3F3;
		}
	}
.details{
	.wb-tit{
			padding: 17px 0;
			text-align: center;
			background-color: #F3F3F3;
			color: #000000;
			font-weight: 600;
		}
	.frombox{
		padding: 50px 80px 50px 50px;
		/deep/ .el-input__inner,/deep/ .el-textarea__inner {
			border-radius: 0px;
		}
		/deep/ .el-button {
			border-radius: 0px;
		}
		/deep/ .el-upload--picture-card {
			width: 80px;
			height: 80px;
			border-radius: 0px;
		}
		/deep/ .el-upload {
			width: 80px;
			height: 80px;
			line-height: 80px;
			border-radius: 0px;
		}
		/deep/ .el-upload-list--picture-card .el-upload-list__item {
			width: 80px;
			height: 80px;
			line-height: 80px;
			border-radius: 0px;
		}
		/deep/ .el-upload-list--picture-card .el-upload-list__item-thumbnail {
			width: 80px;
			height: 80px;
			line-height: 80px;
			border-radius: 0px;
		}
		/deep/ .avatar {
			width: 80px;
			height: 80px;
			border-radius: 0px;
		}
		.demo-ruleForm{
			button{
				width: 155px;
				height: 40px;
			}
			.qux{
				background-color: #fff;
				border: 1px solid #00bcff;
				color: #00bcff;
			}
			.btn-next{
				background-color: #00BCFF;
				border: none;
			}

		}
	}
}









</style>
