<template>
	<el-container style="height:100%;">
		<el-header>
			<navtop></navtop>
		</el-header>
		<main class="main" id="main_id" >
			<div class="" :style="{'min-height': sheight+'px' }" >
<!--				<keep-alive><router-view v-if="$route.meta.keepAlive"></router-view></keep-alive>-->
				<router-view v-if="!$route.meta.keepAlive"></router-view>
			</div>
			<footerbottom></footerbottom>
		</main>
		<!-- <el-footer>
			<footerbottom></footerbottom>
		</el-footer> -->
		<!-- 修改头像 -->
		<modifyavatar></modifyavatar>
		<!-- 注册成功 -->
		<zcsuccess></zcsuccess>
		<!-- 实名失败 -->
		<smfail></smfail>
		<!-- 完善弹窗 -->
		<wanshanPopup></wanshanPopup>
		<!-- 举报弹窗 -->
		<reportPopup></reportPopup>
		<!-- 选择技能 -->
		<choosejineng></choosejineng>
	</el-container>
</template>

<script>
	import navtop from '../components/public/navtop.vue'
	import footerbottom from '../components/public/footerbottom.vue'
	import reportPopup from '../components/position/reportPopup.vue'
	import wanshanPopup from '../components/position/wanshanPopup.vue'
	import choosejineng from '../components/mycenter/personal/choosejineng.vue'
	import modifyavatar from '../components/person/modifyavatar.vue'
	import zcsuccess from '../components/person/zcsuccess.vue'
	import smfail from '../components/person/smfail.vue'
export default {
	components:{
		navtop,
		footerbottom,
		reportPopup,
		wanshanPopup,
		choosejineng,
		modifyavatar,
		zcsuccess,
		smfail
	},
	data() {
		return {
			// menulist: [],
			// isCollapse: false,
			sheight: '',
			// account: '我的工作台',
			// activeIndex: '/home'
		};
	},
	created() {
		this.sheight = document.documentElement.scrollHeight - 408;
		// console.log('h获取到的高度',this.sheight)
	},
	mounted() {
		// document.body.parentNode.style.overflowY = 'hidden';
	},
	methods: {
		handleCommand(command) {
			switch (command) {
				case 'logout':
					this.loginout();
					break;
			}
		},
		loginout() {
      this.$api.outLogin("post").then(() => {
        window.sessionStorage.clear();
        this.$IM.outLogin();
        this.$router.push("/login");
      });
		}
	}
};
</script>

<style lang="less" scoped>
.el-header {
	background-color: #FFFFFF;
	color: #333;
	text-align: center;
	height: 56px !important;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 999;
	box-shadow: 0 0 16px rgba(0, 0, 0, 0.1) !important;
}
.el-footer{
	height: 352px !important;
	padding: 0 0 !important;
	margin: 0 0 !important;
}
.el-aside {
	background-color: #d3dce6;
	color: #333;
	text-align: center;
	line-height: 200px;
}

//.el-main {
//  background-color: #F3F3F3;
//  margin-top: 56px;
//  padding: 0;
//
//}

.main {
  display: block;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  -ms-flex-preferred-size: auto;
  flex-basis: auto;
  overflow-y: visible !important;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background-color: #F3F3F3;
  margin-top: 56px;
  padding: 0;
}


</style>
