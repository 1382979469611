<template>
	<div class="">
		<el-dialog :visible.sync="dialogVisible" width="700px" :show-close="false" :modal-append-to-body="false" :lock-scroll="false">
			<div class="details">
				<!-- <span class="closeicon" @click="closebox()">
					<i class="el-icon-error"></i>
				</span> -->
				<!-- vue-cropper与element ui实现头像图片剪切上传   未做 -->
				<!-- <div class="wb-tit font18">注册成功</div> -->
				<div class="c-main">
					<div class="c-pic">
						<img src="../../assets/images/common/gou-chenggong.png" >
					</div>
					<div class="c-text">
						恭喜你，注册成功
					</div>
					<div class="c-text2">
						完成实名认证，可更好的求职！
					</div>
					<div class="btn">
						<el-button type="primary" @click="toshiming()">点击实名认证</el-button>
					</div>


				</div>



			</div>
		</el-dialog>
	</div>
</template>

<script>
import Bus from "../../common/bus.js"
export default {
	data() {
		return {
			dialogVisible: false,
			imageUrl: '',






		};
	},
	mounted() {
		Bus.$on('zcsuccsestuts', (data) => {
			// console.log('城市选择接受到',data)
			this.dialogVisible=data;
		})
	},
	methods: {
		toshiming(){
			this.dialogVisible=false;
			this.$router.push('/realnameout');
		},

		closebox(){
			this.dialogVisible=false;
			Bus.$emit('modifyavatarstuts', false)
		},
		handleAvatarSuccess(res, file) {
			this.imageUrl = URL.createObjectURL(file.raw);
		},
		beforeAvatarUpload(file) {
			// console.log(file)
			const isJPG = file.type === 'image/jpeg';
			const isLt2M = file.size / 1024 / 1024 < 2;

			if (!isJPG) {
				this.$message.error('上传头像图片只能是 JPG 格式!');
			}
			if (!isLt2M) {
				this.$message.error('上传头像图片大小不能超过 2MB!');
			}
			return isJPG && isLt2M;
		},








	}
};
</script>

<style type="text/css">
	.el-dialog__header,
	.el-dialog__body {
		padding: 0 !important;
	}
	.avatar-uploader{
		text-align: center;
	}
	.avatar-uploader .el-upload {
		/* border: 1px dashed #d9d9d9; */
		/* border-radius: 50px; */
		cursor: pointer;
		position: relative;
		overflow: hidden;
		background-color: #EEEEEE;
	}
	.avatar-uploader .el-upload:hover {
		border-color: #409eff;
	}
	.avatar-uploader-icon {
		font-size: 30px;
		color: #999999;
		width: 92px;
		height: 106px;
		line-height: 106px;
		text-align: center;
	}
	.avatar {
		width: 92px;
		height: 106px;
		display: block;
	}

</style>
<style lang="less" scoped>
.closeicon{
		position: absolute;
		top:-40px;
		right: 0;
		font-size:26px;
		color: #F3F3F3;
	}
.details{
	.wb-tit{
			padding: 17px 0 17px 30px;
			text-align: center;
			background-color: #F3F3F3;
			color: #000000;
			// border-bottom: 1px solid #DDDDDD;
		}




}

.c-main{
	padding: 50px 0;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	.c-pic{
		img{
			width: 80px;
			height: 80px;
		}
	}
	.c-text{
		color: #111111;
		font-size: 26px;
		font-weight: 600;
		margin-top: 20px;
	}
	.c-text2{
		color: #999999;
		font-size: 16px;
		margin-top: 10px;
	}
	.btn{
		margin-top: 50px;
		text-align: center;
		/deep/ .el-button {
			border-radius: 0px;
			width: 150px;
			height: 40px;
			background: #00bcff;
			border: none;
		}
	}
}







</style>
